import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import '@catalogit/common-components-ts/lib/cit-mui-theme.js';

const theme = responsiveFontSizes(
  createTheme({
    palette: {
      mode: 'light',
      primary: {
        light: '#0086A8',
        main: '#005F91',
        dark: '#11374B'
      },
      secondary: {
        main: '#FF8F00',
        contrastText: '#fff'
      },
      neutral: {
        main: '#eeeeee',
        dark: '#bdbdbd'
      },
      common: {
        black: '#000',
        white: '#fff',
        transparent: 'rgba(0, 0, 0, 0)',
        fullBlack: 'rgba(0, 0, 0, 1)',
        darkBlack: 'rgba(0, 0, 0, 0.87)',
        lightBlack: 'rgba(0, 0, 0, 0.54)',
        minBlack: 'rgba(0, 0, 0, 0.26)',
        faintBlack: 'rgba(0, 0, 0, 0.12)',
        fullWhite: 'rgba(255, 255, 255, 1)',
        darkWhite: 'rgba(255, 255, 255, 0.87)',
        lightWhite: 'rgba(255, 255, 255, 0.54)'
      },
      citPlan: {
        museum: '#006EF5',
        personal: '#FF6A59',
        organization: '#00C2FF',
        conservator: '#1FEB89',
        appraiser: '#FF6A59'
      },
      background: {
        default: '#ffffff'
      },
      warning: {
        main: '#F44336'
      },
      success: {
        main: '#1FEB89'
      },
      info: {
        main: '#90CBFF'
      }
    },
    shape: {
      borderRadius: 4
    },
    typography: {
      fontSize: 14,
      fontFamily: 'Roboto'
    },
    components: {
      MuiInput: {
        styleOverrides: {
          underline: {
            '&&&&:hover:before': {
              borderBottom: '2px solid #005F91'
            },
            '&:after': {
              borderBottom: '2px solid #005F91'
            }
          }
        }
      }
    }
  })
);

export default theme;
