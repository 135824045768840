import React from 'react';

import { isNative } from '@catalogit/common/lib/constants/runtime.js';

import { Typography, Button } from '@mui/material';

import PrimarySkeleton from '@catalogit/common-components-ts/lib/layout/PrimarySkeleton.js';

interface ErrorViewProps {
  message?: string | undefined;
}

export default function ErrorView({ message }: ErrorViewProps) {
  const element: React.ReactElement = (
    <PrimarySkeleton>
      <div style={{ margin: 32 }}>
        <Typography style={{ marginBottom: 16 }}>
          An unexpected error occurred. We are very sorry.
        </Typography>
        {isNative && (
          <Typography style={{ marginBottom: 16 }}>
            Please check that you have downloaded and are using the most recent version of
            CatalogIt.
          </Typography>
        )}
        <Typography style={{ marginBottom: 16 }}>
          You will need to reload CatalogIt. If this problem persists, please let us know at{' '}
          <a
            target='_blank'
            href='https://www.catalogit.app/support#contact-us'
            rel='noopener noreferrer'
          >
            support@catalogit.app
          </a>
          .
        </Typography>
        <Button variant='contained' color='primary' onClick={() => (window.location.href = '/')}>
          Reload Now
        </Button>
        {message ? (
          <Typography
            variant='caption'
            style={{ marginTop: 16, display: 'block' }}
          >{`Details: ${message}`}</Typography>
        ) : null}
      </div>
    </PrimarySkeleton>
  );
  return element;
}
